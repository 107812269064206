import * as $ from 'jquery';
import * as helper from './helpers';


export class CoffeeBrief {

    /**
    * The init function for handling click event on the whole coffeebrief teaser on the frontpage
    */
    static InitFrontpage(): void {
        $(".coffeebrief withgraphics").on('click', (e: JQueryEventObject) => this.navigateToCoffeeBrief(e));
        $(".coffeebrief__expander").on('click', (e: JQueryEventObject) => this.handleHeadLineNumber());
    }

    static handleHeadLineNumber(): void {
        const title = document.getElementById('coffebriefminimizedtitle');
        var expanded = $(".coffeebrief__expander").attr('aria-expanded');
        //As the coffeebrief is collapsed, and the expander is clicked on, the expanded value is changed to
        //true before we retrieve the value. Therefore, the expanded value is true when we open the coffebrief,
        //and change the title value to 01, and 1/3 when collapsing the coffeebrief with another expander click.
        if (expanded === 'true') {
            title.className = 'coffeebrief__title-01';
        }
        else {
            title.className = 'coffeebrief__title-collapsed';
        }
    }

    /**
    * Function for navigating to the coffeebrief, given the url in data-href 
    */
    static navigateToCoffeeBrief(e: JQueryEventObject): void {
        e.preventDefault
        let ele: JQuery<Element> = $(e.currentTarget);
        window.location.href = ele.data("href");
    }

    /**
    * The init function for scrolling to the anchor defined by the scrollto parameter in the URL.
    */
    static InitArticle(): void {
        var scrollto = helper.extractUrlParameter("scrollto");
        $(window).on('load', (e: JQueryEventObject) => {
            if (window.location.search) {
                var ele = document.getElementById(scrollto);
                ele.scrollIntoView({
                    behavior: "smooth"
                })
            }
        })
    }
}