export class AppStart {
    static init(): void {


        window.currentUser = {
            userId: "",
            isLoggedIn: false,
            hasAccess: false,
            firstName: "",
            lastName: "",
            fullName: "",
            email: ""
        };
    }    
}