import * as $ from 'jquery';

export class Menu {
    static init(): void {
        $('.hamburger').on('click', function () {
            $('.hamburger').toggleClass('is-active');
            $('.sidebar-overlay').fadeToggle();
            $('.sidebar').toggleClass('sidebar--active');
            $('body').toggleClass('menu-open');
        });
    }
}