//Change background-color on event snippet if event is expired

export class EventDateChecker {

    static init(): void {
        const eventElements = document.querySelectorAll<HTMLElement>('.dre-feature-snippet--isEvent');
        const currentDate = new Date();
        eventElements.forEach(element => {
            const dateElement = element.querySelector<HTMLElement>('dre-data[name="date"]');

            if (dateElement) {
                const eventDate = new Date(dateElement.textContent);
                element.classList.toggle('expired', eventDate < currentDate); // Add class 'expired' if event is expired
            }
        });   
    }
}
