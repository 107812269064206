import * as $ from 'jquery';
import * as helper from './helpers';
import 'bootstrap'

export class CurrentNews {
    static timer: any;

    static InitFrontpage(): void {
        //navigates to navnenyt page
        $('.current-news-teaser').on('click', (e: JQuery.ClickEvent) => {
            var target = $(e.target);
            if (target.is('.current-news-teaser__pagination') ||
                target.is('.current-news-teaser__submit') ||
                target.parents('.current-news-teaser__pagination').length) {
                e.preventDefault;
                e.stopPropagation();
                return;
            }
            e.preventDefault;
            e.stopPropagation();
            window.location.href = "/navnenyt";
        });
    }

    static init(): void {
        //scrolls to the correct story that was clicked in the frontage
        var scrollto = helper.extractUrlParameter("scrollto");
        $(window).on('load', (e: JQuery.ClickEvent) => {
            if (window.location.search) {
                var ele = document.getElementById(scrollto);
                if (ele) {
                    ele.scrollIntoView({
                        behavior: "smooth"
                    })
                }
            }
        })
    }
}