import * as $ from 'jquery';
import Swiper from 'swiper';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export class Carousel {
    private static sliders = [];

    static init() {
        const _this = this;

        $('.swiper-container').each(function (index: number, element: HTMLElement) {
            const e = $(element),
                slidesperviewmobile = (e.data("slidesperview-mobile")),
                slidesperviewsm = (e.data("slidesperview-sm")) || slidesperviewmobile,
                slidesperviewmd = (e.data("slidesperview-md")) || slidesperviewsm,
                slidesperviewlg = (e.data("slidesperview-lg")) || slidesperviewmd,
                slidesperviewxl = (e.data("slidesperview-xl")) || slidesperviewlg,
                spacebetweenmobile = Number(e.data("spacebetween-mobile")),
                spacebetweendesktop = Number(e.data("spacebetween-desktop")) || spacebetweenmobile,
                slidesLoop = Boolean(e.data("slideloop")),
                autoplay = Boolean(e.data("autoplay"));

            let initOffset = ((screen.width - $('.container').outerWidth()) / 2);

            let modulesConfig = [Pagination, Navigation];
            if (autoplay) {
                modulesConfig.push(Autoplay);
            }

            const slider = new Swiper(element, {
                modules: modulesConfig,
                slidesPerView: slidesperviewmobile,
                spaceBetween: spacebetweenmobile,
                slidesOffsetAfter: 0,
                slidesPerGroup: 1,
                autoHeight: false,
                loop: slidesLoop,
                updateOnWindowResize: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: true,
                },
                breakpoints: {
                    576: {
                        slidesPerView: slidesperviewsm,
                        spaceBetween: spacebetweendesktop,
                    },
                    768: {
                        slidesPerView: slidesperviewmd,
                        spaceBetween: spacebetweendesktop,
                    },
                    992: {
                        slidesPerView: slidesperviewlg,
                        spaceBetween: spacebetweendesktop,
                    },
                    1200: {
                        slidesPerView: slidesperviewxl,
                        spaceBetween: spacebetweendesktop,
                    }
                },
                navigation: {
                    nextEl: $(element).parent().find('.swiper-button-next')[0],
                    prevEl: $(element).parent().find('.swiper-button-prev')[0]
                },
                on: {
                    resize: function (swiper: Swiper) {
                        initOffset = ((screen.width - $('.container').outerWidth()) / 2)
                    },
                    init: function () {
                        $('.swiper-slide-active').addClass('active');
                    },
                    transitionStart: function () {
                        $('.swiper-slide-active').removeClass('active');
                    },
                    transitionEnd: function (swiper) {
                        $('.swiper-slide-active').addClass('active');
                    }
                }
            });

            _this.sliders.push(slider);
        });
    }
}