import * as $ from 'jquery';

export class KeyTakeaways {

    static init(): void {
        $("button[data-copy-summary-url-btn]")
            .on('click', function (event: JQuery.ClickEvent) {
                event.preventDefault();
                let url: string = window.location.href.split("#")[0];
                
                navigator.clipboard.writeText(url + "#summary");

                $(".key-takeaways .share-image").hide();
                $(".key-takeaways .share-text").hide();
                $(".key-takeaways .copied-image").show();
                $(".key-takeaways .copied-text").show();
                setTimeout(() => {
                    $(".key-takeaways .copied-image").hide();
                    $(".key-takeaways .copied-text").hide();
                    $(".key-takeaways .share-image").show();
                    $(".key-takeaways .share-text").show();
                }, 4000);
            });
    }
}
